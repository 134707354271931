<script>
  import Layout from "../../layouts/main.vue";
  import appConfig from "../../../app.config";
  import { overlay } from "@/state/helpers";
  import { saError, saSuccess, formatPrice } from '@/helpers/global-functions';
  import { callUrl } from '@/helpers/api-apolo';
  import * as helpersFunction from '@/helpers/helper-functions';
  import Swal from "sweetalert2";
  import Multiselect from "@vueform/multiselect";
  import "@vueform/multiselect/themes/default.css";
  import ChatMessages from "@/components/chat-messages";

  export default {
    page: {
      title: "Chat",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        title: "Chat",
        items: [{
            text: "CRM",
            href: "/",
          },
          {
            text: "Chat",
            active: true,
          },
        ],
        intervalInSecondsToFindChats: 10,
        actualPage: 1,
        totalPages: 0,
        chatData: [],
        chatDataFake: [],
        loadingChats: false,
        chat: {
          id: ''
        },
        selectPipeline: '',
        inputSearchChat: '',
        currentBusiness: '',
        loadingValue: 0,
        loadingBar: 0,
        isLoading: false,
        isLoadingBar: false,
        pipelinesData: [],
        deals: [],
        changed: {},
        showModalLost: false,
        firstLoading: true,
        reasons: [
          {value: 'not_responding', label: 'Não respondeu mais'},
          {value: 'will_postpone_the_process', label: 'Vai adiar o processo'},
          {value: 'canceled_the_process', label: 'Desistiu do processo'},
          {value: 'values_higher_than_expected', label: 'Valores acima do esperado'},
          {value: 'bought_with_the_competition', label: 'Fechou com a concorrência'},
          {value: 'dissatisfaction_with_the_service', label: 'Insatisfação com o serviço'},
        ],
        orderSelected: 'updated_at:0',
        orderOptions: [
          {label: 'Atualizados mais recente', value: 'updated_at:0'},
          {label: 'Mais antigos', value: 'updated_at:1'},
          {label: 'Pendentes de leitura', value: 'pending_to_read:0'},
        ],
        dealToShow: {
          status: 'Aberto'
        }
      };
    },
    computed: {
      isFiltered() {
        return this.selectPipeline || this.inputSearchChat || this.orderSelected !== 'updated_at:0';
      },
      pageCounter() {
        if (this.totalPages) {
          return `${this.actualPage} / ${this.totalPages}`;
        }
        return '-/-';
      },
      pipelinesToFilter() {
        return [{value:'', label:'Todos Pipelines'}].concat(this.pipelinesData.map(pipeline => {
          return {
            'value': pipeline.id,
            'label': pipeline.name
          };
        }));
      },
      chatsToShow() {
        return this.chatDataFake.concat(this.chatData);
      },
      formatActual() {
        if (this.dealToShow.status === 'Fechado') {
          return 'accordion-success';
        }
        if (this.dealToShow.status === 'Perdido') {
          return 'accordion-danger';
        }
        return 'accordion-secondary';
      }
    },
    components: {
      Layout,
      Multiselect,
      ChatMessages
    },
    methods: {
      ...overlay,
      ...helpersFunction,
      async setChat(data) {
        // this.chat = {
        //   id: ''
        // };
        await this.startLoading();
        this.chat = data;
      },
      async startLoading() {
        this.isLoading = true;
        this.loadingValue = 0;
        let variable = 200;

        while (this.loadingValue < 100 && this.isLoading) {
          await new Promise(resolve => setTimeout(resolve, variable));
          this.loadingValue += 10;

          if (this.loadingValue > 40) {
            variable = 50;
          }
        }
      },
      async getChats(reloading = false) {
        try {
          if (this.loadingChats) {
            return;
          }
          this.loadingChats = true;
          const limit = 10;
          if (this.actualPage < 0) {
            this.actualPage = 1;
          }
          const params = [`page=${this.actualPage}`, `limit=${limit}`, `order=${this.orderSelected}`];
          if (this.selectPipeline) {
            params.push(`pipeline_id=${this.selectPipeline}`);
          }
          if (this.inputSearchChat && this.inputSearchChat.length > 3) {
            params.push(`query=${this.inputSearchChat}`);
          }
          if (!reloading) {
            this.chatData = [];
          }
          const dataM = await callUrl({}, `/chat?${params.join('&')}`, 'get', '', false, false);
          if (!dataM.metadata) {
            return;
          }
          this.totalPages = Math.ceil(dataM.metadata.total / limit);
          if (dataM.data && dataM.data.length) {
            for (let i = 0; i < dataM.data.length; i++) {
              if (/\/.jpg$/.test(dataM.data[i].customer.avatar)) {
                dataM.data[i].customer.avatar = "//static.ffid.io/adm/img/avatar-2-64.png";
              }
              if (!reloading) {
                this.chatData.push(dataM.data[i]);
              } else {
                const existedChat = this.chatData.filter(chat => chat.id === dataM.data[i].id);
                if (!existedChat.length) {
                  this.chatData.push(dataM.data[i]);
                } else {
                  this.chatData = this.chatData.map(chat => {
                    if (chat.id === dataM.data[i].id) {
                      chat.pending_to_read = dataM.data[i].pending_to_read;
                      chat.updated_at = dataM.data[i].updated_at;
                    }
                    return chat;
                  });
                }
              }
            }
          }
        } catch (error) {
          saError(error.message);
        } finally {
          this.loadingValue = 100;
          this.isLoading = false;
          this.loadingChats = false;
        }
      },
      async intervalChats() {
        this.startLoading();
        await this.getChats();
        this.interval_chat = setInterval(async () => {
          await this.getChats(true);
        }, this.intervalInSecondsToFindChats * 1000)
      },
      goToPage(page) {
        this.startLoading();
        this.actualPage = page;
        this.getChats();
      },
      findChat () {
        if (this.inputSearchChat.length > 3 || this.inputSearchChat.length === 0) {
          this.goToPage(1);
        }
      },
      async setGain(deal) {
        Swal.fire({
          title: "Você tem certeza?",
          html: "Dar ganho para o negócio <b>" + deal.title + "</b>?",
          icon: "question",
          reverseButtons: true,
          showCancelButton: true,
          confirmButtonText: "SIM",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
          customClass: {
            actions: 'd-flex justify-content-center',
            confirmButton: 'btn btn-success flex-grow-1',
            cancelButton: 'btn btn-outline-dark flex-grow-1'
          }
        }).then(async (result) => {
          if (result.value) {
            try {
              this.changeShowOverlay({show: true});
              await callUrl({}, '/business/' + deal.id + '/done', 'post');
              saSuccess("Parabéns pelo ganho!");
            } catch (error) {
              saError(error.message);
            } finally {
              this.changeShowOverlay({show: false});
            }
          }
        });
      },
      prepareLost(deal) {
        this.showModalLost = true
        this.changed = deal
      },
      cancelLost() {
        this.changed = {}
      },
      async setLost() {
        try {
          this.changeShowOverlay({show: true});
          await callUrl({reason_status: this.changed.reason_status, reason_observation: this.changed.reason_observation}, '/business/' + this.changed.id + '/lossed', 'post');
          saSuccess("Negócio perdido com sucesso 😭😭😭");
          this.cancelLost();
        } catch (error) {
          saError(error.message);
          this.cancelLost();
        } finally {
          this.changeShowOverlay({show: false});
        }
      },
      async getAllPipelines() {
        try {
          this.pipelinesData = [];
          let pipelineFiltered = sessionStorage.getItem('pipelineFiltered');
          const data = await callUrl({}, `/pipeline`, 'get');
          if (data.length) {
            for (let i = 0; i < data.length; i++) {
              this.pipelinesData.push(data[i]);
              pipelineFiltered = !pipelineFiltered && data[i].is_default ? data[i].id : pipelineFiltered;
            }
            // this.selectPipeline = pipelineFiltered;
          }
        } catch (error) {
          saError(error.message);
        }
      },
      async getDeals() {
        this.simulateAPICall();
        this.deals = [];

        if (!this.chat || this.chat.business.length === 0) {
          return;
        }

        const unsortedDeals = [];
        try {
          const data = await callUrl({}, `/chat/${this.chat.id}/business-detail`, 'get');
          if (data) {
            for (let i = 0; i < data.length; i++) {
              const deal = data[i];
              const pipeline = this.pipelinesData.find(pipeline => pipeline.id === deal.pipeline_id);
              let obj = deal;
              obj.pipeline_name = pipeline.name;
    
              const phase = pipeline.phases.find(phase => phase.id === obj.pipeline_phases[0].pipeline_phase_id);
              if (phase) {
                obj.pipeline_actual_phase = phase.name;
              }
              for (let k = 0; k < obj.products.length; k++) {
                obj.products[k].price_formatted = formatPrice(obj.products[k].price);
              }
    
              unsortedDeals.push(obj);
            }
          }
        } catch (error) {
          saError(error.message);
        }

        this.deals = unsortedDeals.sort((a, b) => {
          if (a.status === 'Aberto' && b.status !== 'Aberto') {
            return -1;
          } else if (a.status !== 'Aberto' && b.status === 'Aberto') {
            return 1;
          } else {
            return (new Date(b.created_at)).getTime() - (new Date(a.created_at)).getTime();
          }
        });

        this.cancelSimulateAPICall();
      },
      async openModalDeals() {
        this.openModal('userDealsCanvas');
        await this.getDeals();
      },
      closeModal (elementId) {
        const element = document.getElementById(elementId);
        // element.style["visibility"] = 'hidden';
        element.classList.remove('show');
        document.getElementById('userDealsCanvasBg').classList.remove('show');
        document.getElementById('userDealsCanvasBg').classList.remove('offcanvas-backdrop');
      },
      openModal (elementId) {
        const element = document.getElementById(elementId);
        // element.style["visibility"] = 'visible';
        element.classList.add('show');
        document.getElementById('userDealsCanvasBg').classList.add('offcanvas-backdrop');
        document.getElementById('userDealsCanvasBg').classList.add('show');
      },
      closeChatMessage() {
        this.chat = {
          id: ''
        };
      },
      async createFakeChatByBusiness() {
        try {
          const business = await callUrl({}, `/business/${this.currentBusiness}`, 'get');
          const person = await callUrl({}, `/person/${business.person.id}`, 'get');
          if (!person.communication_validations.whatsapp) {
            saError('Nenhum chat encontrado para o negócio');
            return;
          }
          this.chatDataFake.push({
            id: '',
            pending_to_read: 0,
            business: business,
            total_messages: 0,
            customer: {
              avatar: person.avatar ?? require('@/assets/images/users/user-dummy-img.jpg'),
              email: business.person.email,
              name: business.person.name,
              telephone: business.person.telephone
            },
            updated_at: business.updated_at
          });
          this.setChat(this.chatDataFake[this.chatDataFake.length - 1]);
        } catch (error) {
          console.error(`Falha ao buscar os dados do negocio: ${error.message}`);
        }
      },
      getDisplayedPages() {
        const pages = [];
        const totalPages = this.totalPages;
  
        // Add the sequential pages when the selected page is the first or last button
        if (this.actualPage === 1) {
          for (let i = 1; i <= Math.min(3, totalPages); i++) {
            pages.push(i);
          }
        } else if (this.actualPage === totalPages) {
          for (let i = Math.max(1, totalPages - 2); i <= totalPages; i++) {
            pages.push(i);
          }
        } else {
          // Otherwise, keep the currently displayed pages
          pages.push(this.actualPage - 1, this.actualPage, this.actualPage + 1);
        }
  
        return pages;
      },
      openChatByBusiness() {
        if (this.currentBusiness) {
          const chatToSelect = this.chatData.filter(chat => {
            return chat.business.length > 0
              && chat.business.filter(business => business.business_id === this.currentBusiness).length > 0;
          });
          if (chatToSelect.length > 0) {
            this.setChat(chatToSelect[0]);
            return;
          }
          this.createFakeChatByBusiness();
        }
      },
      cancelSimulateAPICall() {
        clearInterval(this.interval);
        this.loadingBar = 100;
        setTimeout(() => {
          this.loadingBar = 0;
          this.isLoadingBar = false;
        }, 500);
      },
      simulateAPICall() {
        this.isLoadingBar = true;
        let currentProgress = 0;
        let progress = 0;
        let step = 0.5;
  
        this.interval = setInterval(() => {
          currentProgress += step;
          progress = Math.round(Math.atan(currentProgress) / (Math.PI / 2) * 100 * 1000) / 1000;
          this.loadingBar = progress;
  
          if (progress >= 100) {
            this.cancelSimulateAPICall();
          } else if (progress >= 70) {
            step = 0.1;
          }
        }, 100); // 100ms = 0.1 seconds
      },
      formatStatus(deal) {
        if (deal.status === 'Perdido') {
          return 'accordion-danger';
        }
        if (deal.status === 'Fechado') {
          return 'accordion-success';
        }
        return 'accordion-secondary';
      },
      clearFilters() {
        this.inputSearchChat = '';
        this.selectPipeline = '';
        this.orderSelected = 'updated_at:0';
        this.getChats();
      },
      markAsRead(chatId) {
        const chat = this.chatData.find(chat => chat.id === chatId);
        if (chat) {
          chat.pending_to_read = 0;
        }
      },
      async createNewChat(messageObj) {
        if (!this.currentBusiness) {
          return;
        }
        try {
          this.changeShowOverlay({show: true});        
          const body = { business_id: this.currentBusiness, ...messageObj };
          const chat = await callUrl(body, `/chat`, 'post', '', false, false);
          this.chat = chat;
          this.chatDataFake = [];
          this.chatData.unshift(chat);
        } catch (error) {
          saError(error.message);
        } finally {
          this.changeShowOverlay({show: false});
        }
      }
    },
    beforeUnmount () {
      clearInterval(this.interval_chat)
      clearInterval(this.interval);
    },
    async mounted() {
      this.changeShowOverlay({show: true});
      if (this.$route.params.pipelineId && this.$route.params.businessId) {
        sessionStorage.setItem('pipelineFiltered', this.$route.params.pipelineId);
        this.selectPipeline = this.$route.params.pipelineId;
        this.currentBusiness = this.$route.params.businessId;
      }
      await this.getAllPipelines();
      await this.intervalChats();
      this.openChatByBusiness();
      this.changeShowOverlay({show: false});
    },
    watch: {
      async selectPipeline(value) {
          sessionStorage.setItem('pipelineFiltered', value);
          this.startLoading();
          this.chatDataFake = [];
          this.inputSearchChat = '';
          this.actualPage = 1;
          this.chat = {};
          await this.getChats();
      }
    }
  };
</script>

<template>
  <Layout>
    <div class="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1 h-100">
      <div class="chat-leftsidebar">
        <div class="px-4 pt-4 mb-4">
          <div class="row g-3 mb-3">
            <div class="col-10">
              <div class="search-box">
                <input type="text" class="form-control bg-light border-light" v-model="inputSearchChat" @keyup="findChat" placeholder="Pesquisar..." />
                <i class="ri-search-2-line search-icon"></i>
              </div>
            </div>
            <div class="col-2">
                <b-button variant="light" class="y position-relative p-0 avatar-xs rounded" style="height: 37.5px !important; width: 37.5px !important;" data-bs-toggle="offcanvas" data-bs-target="#filterCanvas" aria-controls="filterCanvas">
                  <i class="ri-filter-3-line"></i>
                  <span v-show="isFiltered" class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1"><span class="visually-hidden">Filtros Aplicados</span></span>
                </b-button>
            </div>
          </div>
        </div>

        <div id="page-chats" class="chat-room-list" data-simplebar>
          <div class="chat-message-list" style="background-color: var(--vz-card-bg);">
            <b-progress :value="loadingValue" variant="primary" class="mb-2" style="margin-left: 3px !important;"></b-progress>
            <SimpleBar class="list-unstyled chat-list chat-user-list">
              <li v-for="data of chatsToShow" :key="data.id" :class="{ active: this.chat.id === data.id }">
                <a href="#" @click.prevent="setChat(data)">
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                      <div v-if="data.customer.avatar" class="avatar-xs">
                        <img :src="`${data.customer.avatar}`" class="rounded-circle img-fluid userprofile" />
                      </div>
                      <div v-if="!data.customer.avatar" class="avatar-xs">
                        <div class="avatar-title rounded-circle bg-danger userprofile">{{ data.customer.name.charAt(0) }}</div>
                      </div>
                      <span class="user-status"></span>
                    </div>
                    <div class="flex-grow-1 overflow-hidden">
                      <p class="text-truncate mb-0">{{ data.customer.name }}</p>
                      <p class="text-truncate mb-0" style="font-size: 8px !important;">
                        {{ getDiffDateFriendly(data.updated_at) }}
                      </p>
                    </div>
                    <div class="flex-shrink-0" v-show="data.pending_to_read > 0">
                      <span class="badge bg-secondary rounded p-1">{{data.pending_to_read}}</span>
                    </div>
                  </div>
                </a>
              </li>
            </SimpleBar>
          </div>
        </div>
        <nav class="pagination-container" aria-label="...">
          <ul class="pagination" style="margin-bottom: 0 !important;">
            <li class="page-item" :class="{ disabled: actualPage === 1 }">
              <a class="page-link" href="#" @click.prevent="goToPage(1)"><i class="mdi mdi-chevron-double-left"></i></a>
            </li>
            <li class="page-item" :class="{ disabled: actualPage === 1 }">
              <a class="page-link" href="#" @click.prevent="goToPage(actualPage - 1)"><i class="mdi mdi-chevron-left"></i></a>
            </li>
            <li class="page-item" v-for="page in getDisplayedPages()" :key="page" :class="{ active: actualPage === page }">
              <a class="page-link" style="height: 39px !important;" href="#" @click.prevent="goToPage(page)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: actualPage === totalPages }">
              <a class="page-link" href="#" @click.prevent="goToPage(actualPage + 1)"><i class="mdi mdi-chevron-right"></i></a>
            </li>
            <li class="page-item" :class="{ disabled: actualPage === totalPages }">
              <a class="page-link" href="#" @click.prevent="goToPage(totalPages)"><i class="mdi mdi-chevron-double-right"></i></a>
            </li>
          </ul>
        </nav>
      </div>
      <chat-messages
        v-if="chat.id || chat.updated_at"
        :chatSelected="chat"
        :key="chat.id"
        :showDealsButton="true"
        :showProfileButton="true"
        id="chat-message-content"
        :class="['user-chat', 'user-chat-show']"
        @openModalDeals="openModalDeals"
        @openModalProfile="openModal('userProfileCanvas')"
        @close="closeChatMessage"
        @createNewChat="createNewChat"
        @markAsRead="markAsRead"
      />
    </div>
    <div class="offcanvas offcanvas-end border-0" tabindex="-1" id="userProfileCanvas" aria-modal="true" role="dialog" style="visibility: visible;">
        <div class="offcanvas-body profile-offcanvas p-0">
            <div class="team-cover">
                <img src="@/assets/images/small/img-9.jpg" alt="" class="img-fluid">
            </div>
            <div class="p-1 pb-4 pt-0">
                <div class="team-settings">
                    <div class="row g-0">
                        <div class="col">
                            <div class="btn nav-btn">
                                <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close" @click="closeModal('userProfileCanvas')"></button>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="user-chat-nav d-flex">
                                <button type="button" class="btn nav-btn favourite-btn active">
                                    <i class="ri-star-fill"></i>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="p-3 text-center">
                <img :src="chat?.customer?.avatar ? chat.customer.avatar : require('@/assets/images/users/user-dummy-img.jpg')" class="avatar-lg img-thumbnail rounded-circle mx-auto">
                <div class="mt-3">
                    <h5 class="fs-16 mb-1"><a href="javascript:void(0);" class="link-primary username">{{chat?.customer?.name}}</a></h5>
                    <p class="text-muted"><i class="ri-checkbox-blank-circle-fill me-1 align-bottom text-success"></i>Online</p>
                </div>

                <div class="d-flex gap-2 justify-content-center">
                    <button type="button" class="btn avatar-xs p-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Oportunidade">
                        <span class="avatar-title rounded bg-light text-body">
                            <i class="ri-star-line"></i>
                        </span>
                    </button>

                    <a type="button" class="btn avatar-xs p-0" title="Ligar Telefone" :href="`tel:${chat?.customer?.telephone}`">
                        <span class="avatar-title rounded bg-light text-body">
                            <i class="ri-phone-line"></i>
                        </span>
                    </a>
                    <a type="button" class="btn avatar-xs p-0" title="Enviar Email" :href="`mailto:${chat?.customer?.email}`">
                        <span class="avatar-title rounded bg-light text-body">
                            <i class="ri-mail-line"></i>
                        </span>
                    </a>
                </div>
            </div>

            <div class="border-top border-top-dashed p-3">
                <h5 class="fs-15 mb-3">Informações da Pessoa</h5>
                <div class="mb-3">
                    <p class="text-muted text-uppercase fw-medium fs-12 mb-1">Telefone</p>
                    <h6>{{chat?.customer?.telephone}}</h6>
                </div>
                <div class="mb-3">
                    <p class="text-muted text-uppercase fw-medium fs-12 mb-1">Email</p>
                    <h6>{{chat?.customer?.email}}</h6>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas offcanvas-start" tabindex="-1" id="filterCanvas" aria-labelledby="filterCanvasLabel" aria-modal="true" role="dialog" style="visibility: visible;">
        <div class="offcanvas-header">
            <h5 id="offcanvasLeftLabel">Filtros</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="chat-filters">
                <div class="px-4 pt-4 mb-4">
                  <div class="row g-3 mb-3">
                    <div class="col-12">
                      <div class="d-grid gap-2" >
                        <b-button variant="outline-primary" @click.prevent="clearFilters">
                          <i class="ri-delete-bin-line"></i>
                          Remover Filtros
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <div class="row g-3 mb-3">
                    <div class="col-12">
                      <div class="search-box">
                        <input type="text" class="form-control bg-light border-light" v-model="inputSearchChat" @keyup="findChat" placeholder="Pesquisar..." />
                        <i class="ri-search-2-line search-icon"></i>
                      </div>
                    </div>
                  </div>
                  <div class="row g-3 mb-3">
                    <div class="col-12">
                      <div class="d-flex hastck gap-2 flex-wrap">
                        <div class="d-flex align-items-center gap-2" style="width: -webkit-fill-available !important">
                          <Multiselect :canClear="false" class="form-control" v-model="selectPipeline" :close-on-select="true" :searchable="true" :create-option="true" :options="pipelinesToFilter" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <select class="form-select" name="order" v-model="orderSelected" @change="goToPage(1)">
                      <option v-for="order of orderOptions" :key="order.value" :value="order.value">{{ order.label }}</option>
                    </select>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas offcanvas-end border-0" tabindex="-1" id="userDealsCanvas" aria-modal="true" role="dialog" style="visibility: visible;">
        <div class="offcanvas-body profile-offcanvas p-0">
            <div class="team-cover">
                <img src="@/assets/images/small/img-9.jpg" alt="" class="img-fluid">
            </div>
            <div class="p-1 pb-4 pt-0">
                <div class="team-settings">
                    <div class="row g-0">
                        <div class="col">
                            <div class="btn nav-btn">
                                <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close" @click="closeModal('userDealsCanvas')"></button>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="user-chat-nav d-flex">
                                <button type="button" class="btn nav-btn favourite-btn active">
                                    <i class="ri-star-fill"></i>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="p-3 text-center">
                <img :src="chat?.customer?.avatar ? chat.customer.avatar : require('@/assets/images/users/user-dummy-img.jpg')" class="avatar-lg img-thumbnail rounded-circle mx-auto">
                <div class="mt-3">
                    <h5 class="fs-16 mb-1"><a href="javascript:void(0);" class="link-primary username">{{chat?.customer?.name}}</a></h5>
                    <p class="text-muted"><i class="ri-checkbox-blank-circle-fill me-1 align-bottom text-success"></i>Online</p>
                </div>

                <div class="d-flex gap-2 justify-content-center">
                    <button type="button" class="btn avatar-xs p-0" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Oportunidade">
                        <span class="avatar-title rounded bg-light text-body">
                            <i class="ri-star-line"></i>
                        </span>
                    </button>

                    <a type="button" class="btn avatar-xs p-0" title="Ligar Telefone" :href="`tel:${chat?.customer?.telephone}`">
                        <span class="avatar-title rounded bg-light text-body">
                            <i class="ri-phone-line"></i>
                        </span>
                    </a>
                    <a type="button" class="btn avatar-xs p-0" title="Enviar Email" :href="`mailto:${chat?.customer?.email}`">
                        <span class="avatar-title rounded bg-light text-body">
                            <i class="ri-mail-line"></i>
                        </span>
                    </a>
                </div>
            </div>

            <div class="border-top border-top-dashed p-3">
                <h5 class="fs-15 mb-3">Informações da Pessoa</h5>
                <div class="mb-3">
                    <p class="text-muted text-uppercase fw-medium fs-12 mb-1">Telefone</p>
                    <h6>{{chat?.customer?.telephone}}</h6>
                </div>
                <div class="mb-3">
                    <p class="text-muted text-uppercase fw-medium fs-12 mb-1">Email</p>
                    <h6>{{chat?.customer?.email}}</h6>
                </div>
            </div>

            <div class="border-top border-top-dashed p-3">
                <b-progress v-show="isLoadingBar" :value="loadingBar" class="bg-gradient progress-sm mb-0" variant="primary"></b-progress>
                <h5 class="fs-15 mb-3" v-if="deals.length === 0 && !isLoadingBar">Não há Negócios</h5>
                <h5 class="fs-15 mb-3" v-if="isLoadingBar">Verificando Negócios...</h5>
                <h5 class="fs-15 mb-3" v-if="deals.length === 1">1 Negócio</h5>
                <h5 class="fs-15 mb-3" v-if="deals.length > 1">{{ deals.length }} Negócios</h5>

                <b-accordion :class="['custom-accordionwithicon-plus', 'custom-accordion-border', 'accordion-border-box', formatActual]" id="accordionWithplusicon">
                  <b-accordion-item :class="formatStatus(deal)" v-for="(deal, index) of deals" :key="index" :title="deal.title" :visible="index === 0" @click.prevent="dealToShow = deal">
                    <b-list-group flush>
                      <b-list-group-item><router-link :to="'/apps/deal-details/' + deal.id">Código: <b>{{deal.code}}</b></router-link></b-list-group-item>
                      <b-list-group-item>Pipeline: <b>{{deal.pipeline_name}}</b></b-list-group-item>
                      <b-list-group-item>Fase: <b>{{deal.pipeline_actual_phase}}</b></b-list-group-item>
                      <b-list-group-item>Status: <b>{{deal.status}}</b></b-list-group-item>
                      <b-list-group-item v-if="deal.description !== ''">Descrição: <b>{{deal.description}}</b></b-list-group-item>
                      <b-list-group-item v-if="deal.products.length > 0">Produtos:
                        <div class="flex-grow-1" >
                            <span class="badge badge-soft-primary me-1" v-for="(product, ind) of deal.products" :key="ind">{{ product.name }} - {{product.price_formatted}}</span>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                    <b-button-group role="group" aria-label="Actions" style="width: 100%; gap: 0.75rem !important;" v-if="deal.status === 'Aberto'">
                      <b-button variant="danger" class="btn-label waves-effect waves-light" data-bs-dismiss="offcanvas" @click.prevent="prepareLost(deal)"><i class="ri-thumb-down-fill label-icon align-middle fs-16 me-2"></i> Perda</b-button>
                      <b-button variant="success" class="btn-label waves-effect waves-light" data-bs-dismiss="offcanvas" @click.prevent="setGain(deal)"><i class="ri-thumb-up-fill label-icon align-middle fs-16 me-2"></i> Ganho</b-button>
                    </b-button-group>
                  </b-accordion-item>
                </b-accordion>
            </div>
        </div>
    </div>
    <b-modal v-model="showModalLost" hide-footer class="v-modal-custom" title="Você tem certeza?" modal-class="zoomIn" centered>
        <h6>Dar perda para o negócio <b>{{changed.title}}</b>?</h6>
        <form>
            <div class="mb-3">
                <label for="recipient-name" class="col-form-label">Motivo:</label>
                <select class="form-select mb-3" v-model="changed.reason_status" aria-label="Selecione o Motivo">
                    <option v-for="(reason, key) in reasons" :key="'reason-' + key" :value="reason.label">{{reason.label}}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="message-text" class="col-form-label">Observação:</label>
                <textarea class="form-control" v-model="changed.reason_observation" id="message-text"></textarea>
            </div>
        </form>
        <div class="modal-footer swal2-actions" style="margin: 0 0 15px 0 !important;">
            <button type="button" style="margin: 0 !important;" class="btn btn-outline-dark" data-bs-dismiss="modal" @click="cancelLost">Close</button>
            <button type="button" style="margin: 0 !important;" class="btn btn-success" @click.prevent="setLost" data-bs-dismiss="modal">SIM 😱</button>
        </div>
    </b-modal>
    <div class="fade" id="userDealsCanvasBg" @click="closeModal('userDealsCanvas')"></div>
  </Layout>
</template>

<style scoped>
#page-chats {
  height: 100%;
  /*height: calc(100% - 270px);*/
}
#page-chats .simplebar-wrapper,
#page-chats .simplebar-content,
#page-chats .chat-message-list {
  height: 100%;
}
#page-chats .chat-message-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#page-chats .chat-user-list {
  flex-grow: 1;
  overflow-y: auto;
}
.pagination-container {
  position: absolute;
  bottom: 2px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}
.pagination_chat {
  bottom: 0;
}
.pagination_chat .counter {
  font-size: 1.1em;
  margin-top: 7px;
}
#chat-message-content {
  flex: 1 1 auto;
  overflow: hidden;
  height: calc(100vh - 70px - 60px + 25px);
  background: url('~@/assets/images/chat-bg-pattern.png');
  background-color: var(--bs-body-bg);
}
.accordion-secondary.accordion-item {
    border-color: rgba(53, 119, 241, 0.6);
}
.accordion-dark.accordion-item {
    border-color: rgba(33, 37, 41, 0.6);
}
.accordion-danger.accordion-item {
    border-color: rgba(206, 43, 55, 0.6);
}
.accordion-success.accordion-item {
    border-color: rgba(10, 179, 156, 0.6);
}
.accordion-item.accordion-secondary > .accordion-header > .accordion-button:not(.collapsed) {
    color: #3577f1;
    background-color: rgba(53, 119, 241, 0.1) !important;
}
.accordion-item.accordion-dark > .accordion-header > .accordion-button:not(.collapsed) {
    color: #3577f1;
    background-color: rgba(53, 119, 241, 0.1) !important;
}
.custom-accordion-border.accordion-item {
    border-left: 3px solid var(--vz-border-color);
}
.accordion-border-box.accordion-item {
    border-top: 1px solid var(--vz-border-color);
    border-radius: 0.25rem;
}
@media screen and (max-width: 991px) {
  .chat-wrapper .chat-leftsidebar {
    height: calc(100vh - 70px - 30px);
  }
  #chat-message-content {
    height: 100%;
  }
}
</style>
